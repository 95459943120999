body {
    margin-top: 35px;
}

.feature {
    border: none !important;
    text-align: center;
}

.plan {
    padding: 0 !important;
    overflow: hidden;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
}

.plan:hover {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    z-index: 1;
}

.plan-ribbon {
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    background-color: #21ba45;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 55px;
    min-width: 200px;
    text-align: center;
    position: absolute;
    top: 0px;
    right: -45px;
    -webkit-transform: rotate(40deg);
    -moz-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    -o-transform: rotate(40deg);
    transform: rotate(45deg);
    -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0 0.2);
    -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0 0.2);
    box-shadow: 1px 1px 8px rgba(0, 0, 0 0.2);
    z-index: 1;
    line-height: 10px;
}

.price-table-column {
    padding: 0.5em !important;
}

.price-table-column-segment {
    border-radius: 1em !important;
}

.ui.inverted.attached.segment {
    border-color: transparent !important;
}

.plan-divider-separator {
    border-top-style: solid;
    border-top-width: 2px;
    border-top-color: #4c4c4c;
    width: 30%;
}
