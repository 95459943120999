.indexedStep {
    color: white;
    width: 30px;
    height: 30px;
    font-size: 12px;
    background-color: #cacbcd;
    border-radius: 50%;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
}

.indexedStep.accomplished {
    background-color: #0674fd;
}