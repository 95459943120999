.ui.selectable.table tbody tr:hover,
.ui.table tbody tr td.selectable:hover {
    background: rgba(0, 0, 0, 0.095) !important;
}

#container {
    -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    -moz-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    min-height: 100vh;
    background-color: white;
}

.ui.inverted.menu {
    background: #01d0fa
}

.ui.menu {
    min-height: 3.845726em;
    font-size: 1.038685em;
    margin: 0;
}

.ui.segment {
    margin: 0;
}

.ui.header {
    margin: 0;
}

.row {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    cursor: pointer;
}

.no-pointer {
    cursor: default !important;
}

.pointer {
    cursor: pointer !important;
}

.row span {
    padding-left: 36px;
}

.ui.container {
    width: 1200px;
}

.box-shadow {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.padding-0 {
    padding: 0 !important;
}

.colored-box {
    padding: 0 !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.center-flex-container {
    height: 10%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    /*align-content: center;*/
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.eval-header {
    padding: 0 3em;
    margin-right: 0 !important;
    margin-left: 0 !important;
    background-color: #0674fd;
    color: white;
}

.header-logo:hover {
    background: none !important;
}

.center-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.ui.yellow.label, .ui.yellow.labels .label {
    background-color: orange !important;
    border-color: orange !important;
    color: #fff !important;
}