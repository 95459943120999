/* ADDED BY TUSHAR */
.tick text {
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

text {
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#chart-popup {
    position: absolute;
    z-index: 100;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 8px 12px;
    visibility: hidden;
    background-color: #ffffffdd;
    color: #000;
    font-weight: 600;
    -webkit-box-shadow: 0 0 24px 0 #777;
    -moz-box-shadow: 0 0 24px 0 #777;
    box-shadow: 0 0 24px 0 #777;
}