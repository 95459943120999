.report-summary {
    display: flex;
    justify-content: center;
    margin-top: 0.5em;
}

.report-info-bar {
    margin-top: 1em;
}

.summary-row {
    padding: 0 0 1em 0;
}

.ui.progress .bar {
    min-width: 1em;
}